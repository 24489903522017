import React from 'react';
import { Button, Image } from 'antd';

import AdSlod from '../AdSlot/Index.js'
import styles from '../../scss/PublicComponentsStyle/PublicPopup.module.scss'
import CloseIcon from '../../assets/public_icon/close_icon.svg'
import BackIcon from '../../assets/public_icon/left_icon.svg'

const PublicPopup = ({ title, closefun, backfun, isOverlay, children, isAd, isSlodCenter }) => {

    return (
        <div className={styles.public_popup}>
            {isOverlay ?
                <div className={styles.overlay}>
                    {isAd ? <AdSlod slodCenter={isSlodCenter}><div className={styles.public_popup_bg}>
                        <div className={styles.header}>
                            {backfun ? <Button className={[styles.header_left, "close_button"]} onClick={backfun}><Image preview={false} src={BackIcon} /></Button> : ""}
                            {title ? <div className={styles.title}>{title}</div> : ""}
                            {closefun ? <Button className={[styles.header_right, "close_button"]} onClick={closefun} ><Image preview={false} src={CloseIcon} /></Button> : ""}
                        </div>
                        <div className={styles.content}>
                            {children}
                        </div>
                    </div></AdSlod> : <div className={styles.public_popup_bg}>
                        <div className={styles.header}>
                            {backfun ? <Button className={[styles.header_left, "close_button"]} onClick={backfun}><Image preview={false} src={BackIcon} /></Button> : ""}
                            {title ? <div className={styles.title}>{title}</div> : ""}
                            {closefun ? <Button className={[styles.header_right, "close_button"]} onClick={closefun} ><Image preview={false} src={CloseIcon} /></Button> : ""}
                        </div>
                        <div className={styles.content}>
                            {children}
                        </div>
                    </div>}
                </div>
                : <div className={styles.public_popup_bg}>
                    <div className={styles.header}>
                        {backfun ? <Button className={[styles.header_left, "close_button"]} onClick={backfun}><Image preview={false} src={BackIcon} /></Button> : ""}
                        {title ? <div className={styles.title}>{title}</div> : ""}
                        {closefun ? <Button className={[styles.header_right, "close_button"]} onClick={closefun} ><Image preview={false} src={CloseIcon} /></Button> : ""}
                    </div>
                    <div className={styles.content}>
                        {children}
                    </div>
                </div>}
        </div>
    )
}

export default PublicPopup