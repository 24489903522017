import { React, useEffect, useState } from 'react'
import { Image, Input, Spin } from 'antd'
import { t } from 'i18next';

import { postNftDetailList, getUserOnlineNtfDetail, postNftTetrisAttributes, depositNft, withdrawNft } from '../../../api'
import { openNotification } from '../../../tools/notification'
import { NFT_CONTRACT_ADDRESS } from '../../../tools/tokenConfig'
import { IMAGE_BASEURL } from '../../../tools/gameConfig'
import { viewCode } from '../../../tools/viewCode'
import { sendNFT } from '../../../tools/wallet';

import CommonDialogSmall from '../../PublicComponents/CommonDialogSmall';
import CommonPagination from '../../PublicComponents/Pagination';
import NoData from '../../PublicComponents/NoData';
import '../../../scss/Hall/PersonalCenter/TransferNft.scss'
import OpenEye from "../../../assets/web/open_eye.png"
import OffEye from "../../../assets/web/off_eye.png"


export const TransferNft = (props) => {

    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)
    const [loading, setLoading] = useState(false)
    const [lockedNft, setLockedNft] = useState({})
    // on chain NFT
    const [nftDepositPage, setNftDepositPage] = useState(1)
    const [nftDepositTotal, setNftDepositTotal] = useState(0)
    const [nftAttributesList, setNftAttributesList] = useState([])
    const [nftDepositList, setNftDepositList] = useState([])
    const [nftDepositDetailList, setNftDepositDetailList] = useState([])
    // on game NFT
    const [nftWithdrawPage, setNftWithdrawPage] = useState(1)
    const [nftWithdrawTotal, setNftWithdrawTotal] = useState(0)
    const [nftWithdrawList, setNftWithdrawList] = useState([])
    const [nftWithdrawDetailList, setNftWithdrawDetailList] = useState([])

    const [nftObj, setNftObj] = useState({})

    useEffect(() => {
        gotoStep1()
    }, [props.type])

    useEffect(() => {
        loadDate()
    }, [nftDepositPage, nftWithdrawPage])

    useEffect(() => {
        if (nftDepositList.length > 0 && loading) {
            getNftAttriutes()
        }
    }, [nftDepositList])

    useEffect(() => {
        if (nftAttributesList.length > 0 && loading) {
            getNftDetailDepositList()
        }
    }, [nftAttributesList])

    useEffect(() => {
        if (nftWithdrawList.length > 0 && loading) {
            getNftDetailWithdrawList()
        }
    }, [nftWithdrawList])

    useEffect(() => {
        if (nftWithdrawDetailList.length > 0 && loading && props.type === "withdraw") {
            setLoading(false)
        }
    }, [nftWithdrawDetailList])

    useEffect(() => {
        if (nftDepositDetailList.length > 0 && loading && props.type === "deposit") {
            setLoading(false)
        }
    }, [nftDepositDetailList])

    const getNftDetailList = (type) => {
        setLoading(true)
        if (type === "deposit") {
            getUserOnlineNtfDetail({
                address: props.addr,
                contractId: NFT_CONTRACT_ADDRESS,
                page: nftDepositPage,
                size: 12
            }).then((res) => {
                if (!res.data.code) {
                    if (res.data.data.data.length > 0) {
                        setNftDepositList(res.data.data.data)
                        setNftDepositTotal(res.data.data.total)
                    } else if (type === "deposit") {
                        setLoading(false)
                    }
                } else if (type === "deposit") {
                    setLoading(false)
                }
            }).catch((err) => {
                console.log(err)
                if (type === "deposit") {
                    setLoading(false)
                    openNotification(t("responseCode.networkFailed"))
                }
            })
        } else if (type === "withdraw") {
            postNftDetailList({
                "nftNetworkStatusFilter": "InGame",
                "pagination": {
                    "page": nftWithdrawPage,
                    "size": 12
                }
            }).then((res) => {
                if (!res.data.code) {
                    if (res.data.data.userNFTs) {
                        setNftWithdrawList(res.data.data.userNFTs)
                        setNftWithdrawTotal(res.data.data.pagination.total)
                    } else if (type === "withdraw") {
                        setLoading(false)
                    }
                } else if (type === "withdraw") {
                    setLoading(false)
                    openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
                }
            }).catch((err) => {
                console.log(err)
                if (type === "withdraw") {
                    setLoading(false)
                    openNotification(t("responseCode.networkFailed"))
                }
            })
        }
    }

    const getNftDetailWithdrawList = async () => {
        let nftDataList = []
        for (let index = 0; index < nftWithdrawList.length; index++) {
            const item = nftWithdrawList[index];
            let nftObj = {}
            nftObj.ID = item.ID
            nftObj.Locked = item.Locked
            nftObj.TetrisNftID = item.TetrisNftID
            nftObj.NftTokenID = item.NftTokenID
            nftObj.NftTokenIndex = item.NftTokenIndex
            nftObj.power = item.NftAttributes?.attributes?.power || ""
            nftObj.nft_type = item.NftAttributes?.attributes?.quality || ""
            nftObj.url = item.NftAttributes?.content ? IMAGE_BASEURL + item.NftAttributes?.content + ".png" : ""
            nftObj.NftValidStatus = item.NftValidStatus
            nftDataList.push(nftObj)
        }
        setNftWithdrawList([])
        setNftWithdrawDetailList([...nftDataList])
    }
    const getNftAttriutes = async () => {
        let ipfsArray = []
        nftDepositList.map((item, index) => {
            ipfsArray.push(item.Attributes?.Description)
        })
        let respond = await postNftTetrisAttributes({ dbputTxIds: ipfsArray }).catch((err) => {
            console.log(err)
        })
        if (respond?.data?.code === 0 && respond.data.data?.length > 0) {
            setNftAttributesList(respond.data.data)
        } else {
            setNftAttributesList([""])
        }
    }

    const getNftDetailDepositList = async () => {
        let nftDataList = []
        for (let index = 0; index < nftDepositList.length; index++) {
            const item = nftDepositList[index];
            let nftObj = {}
            nftObj.ID = item.Index
            nftObj.Locked = false
            nftObj.NftTokenID = item.NftTokenId
            nftObj.NftTokenIndex = item.Index
            let attributes = nftAttributesList.find((attObj) => {
                return attObj.dbPutTxId === item.Attributes?.Description
            })
            nftObj.power = attributes?.attributes?.attributes?.power || ""
            nftObj.nft_type = attributes?.attributes?.attributes?.quality || ""
            nftObj.url = attributes?.attributes?.content ? IMAGE_BASEURL + attributes?.attributes?.content + ".png" : " "
            nftDataList.push(nftObj)
        }
        setNftAttributesList([])
        setNftDepositList([])
        setNftDepositDetailList([...nftDataList])
    }


    const gotoStep2 = () => {
        setStep2(true)
        setStep1(false)
    }

    const loadDate = () => {
        if (props.type === "deposit" && nftDepositPage !== 1) {
            setStep1(true)
            setStep2(false)
            setNftObj({})
            getNftDetailList("deposit")
        } else if (props.type === "withdraw" && nftWithdrawPage !== 1) {
            setStep1(true)
            setStep2(false)
            setNftObj({})
            getNftDetailList("withdraw")
        }
    }

    const gotoStep1 = () => {
        setStep1(true)
        setStep2(false)
        setNftDepositPage(1)
        setNftWithdrawPage(1)
        setNftObj({})
        if (props.type === "deposit") {
            setNftDepositList([])
            setNftDepositDetailList([])
            getNftDetailList("deposit")
        } else if (props.type === "withdraw") {
            setNftWithdrawList([])
            setNftWithdrawDetailList([])
            getNftDetailList("withdraw")
        }
    }

    const gotoStep11 = () => {
        setLockedNft(nftObj)
        gotoStep1()
        setTimeout(() => {
            setLockedNft({})
            gotoStep1()
        }, 10000);
    }

    const next = (index) => {
        if (index !== -1) {
            if (props.type === "withdraw") {
                setNftObj(nftWithdrawDetailList[index])
            }
            else if (props.type === "deposit") {
                window._czc && window._czc.push(["_trackEvent", "转入NFT", "点击", "下一步"]);
                setNftObj(nftDepositDetailList[index])
            }
            gotoStep2()
        }
    }
    const load = (page) => {
        if (props.type === "deposit") {
            setNftDepositPage(page)
        } else if (props.type === "withdraw") {
            setNftWithdrawPage(page)
        }
    }

    let content = ""
    if (step1) {
        content = <TransferInNftStep1
            lockedNft={lockedNft}
            total={props.type === "withdraw" ? nftWithdrawTotal : nftDepositTotal}
            nftData={props.type === "withdraw" ? nftWithdrawDetailList : nftDepositDetailList}
            page={props.type === "withdraw" ? nftWithdrawPage : nftDepositPage}
            next={next}
            loading={loading}
            load={load}
        />
    } else if (step2) {
        content = <TransferInNftStep2
            nftObj={nftObj}
            type={props.type}
            addr={props.addr}
            forgetPwd={props.forgetPwd}
            gotoStep1={gotoStep11} />
    }

    return (
        <>
            {content}
        </>
    )
}

let secondInterval = null;
function TransferInNftStep1(props) {

    const [nftData, setNftData] = useState([])
    const [select, setSelect] = useState(-1)
    const [seconds, setSeconds] = useState(0);
    useEffect(() => {
        setSeconds(5)
        setNftData(props.nftData)
        return () => {
            setSeconds(0)
            setNftData([])
            setSelect(-1)
        }
    }, [props.nftData])

    useEffect(() => {
        if (seconds <= 0) {
            secondInterval && clearInterval(secondInterval);
        } else if (seconds === 5) {
            secondInterval && clearInterval(secondInterval);
            secondInterval = setInterval(() => {
                setSeconds((n) => {
                    return n - 1;
                });
            }, 1000);
        }
    }, [seconds]);

    const onItemClick = (item, index) => {
        if (item.Locked || props.lockedNft?.ID === item.ID || (item.NftValidStatus && item.NftValidStatus !== "InActivated")) {
            return
        }
        if (index === select) {
            setSelect(-1);
        } else {
            setSelect(index)
        }
    }

    const pageChange = (e) => {
        props.load(e)
    }

    return (
        <div className='nft-list-containerxx'>
            {props.loading ? <Spin></Spin> :
                <>
                    <div className="page_div">
                        <CommonPagination onChange={pageChange} pageSize={12} current={props.page} total={props.total} />
                    </div>
                    <div className='nft_list'>
                        {nftData.length > 0 ?
                            <div className='nft-list'>{nftData.map((item, index) => {
                                return (
                                    <div className={item.Locked || props.lockedNft.ID === item.ID || (item.NftValidStatus && item.NftValidStatus !== "InActivated") ? "nft-item nft-disabled" : select === index ? "nft-item select" : "nft-item"} key={item.ID} onClick={() => onItemClick(item, index)}>
                                        {props.lockedNft.ID === item.ID ?
                                            <div className='locked_seconds'><div>{t("wallet.transferring")}</div><span>{seconds}</span></div>
                                            : ""}
                                        <Image preview={false}
                                            className={'nft_img'}
                                            src={item.url + "?imageView2/1/w/200/h/200"}
                                            placeholder={
                                                <Image preview={false}
                                                    className={'nft-img'}
                                                    src={item.url + "?imageView2/1/w/50/h/50"}
                                                />
                                            }
                                        />
                                        <div className='nft_detail'>
                                            <span className="nft_id">nft:{item.NftTokenIndex}</span>
                                            <div className={"line " + item.nft_type}></div>
                                            <span className={"nft_type " + item.nft_type}>{t("game.ability")}:{item.power}</span>
                                        </div>
                                    </div>
                                )
                            })}</div> : <NoData />
                        }
                    </div></>
            }

            {!props.loading ? <div className='option_div'>
                <div className={select !== -1 ? "button_common primary_button" : "button_common disabled_button"} onClick={() => { props.next(select) }}>{t("user.next")}</div>
            </div> : ""}
        </div>
    )
}

function TransferInNftStep2(props) {

    const gotoStep1 = () => {
        props.gotoStep1()
    }

    const [nftData, setNftData] = useState([])
    const [load, setLoad] = useState(false)

    const [open, setOpen] = useState(false)
    const [pwd, setPwd] = useState("");
    const [pwdError, setPwdError] = useState(false)

    useEffect(() => {
        return () => {
            setLoad(false)
        }
    }, [])

    useEffect(() => {
        if (props.nftObj.NftTokenID) {
            setNftData([props.nftObj])
        }

        return () => {
            setNftData([])
        }

    }, [props.nftObj])

    const forgetPwd = () => {
        props.forgetPwd()
    }


    const gotoNext = async (type) => {
        if (load) { return }
        if (type === "deposit") {
            if (props.nftObj.NftTokenID) {
                let res = {}
                setOpen(true)
                res = await sendNFT(props.nftObj.NftTokenID)
                setOpen(false)
                if (res.result) {
                    setLoad(true)
                    depositNft({ nftTokenIndex: props.nftObj.NftTokenIndex, transactionId: res.transactionId })
                        .then(val => {
                            window._czc && window._czc.push(["_trackEvent", "转入NFT", "点击", "转入完成"]);
                            gotoStep1()
                        }).catch(err => {
                            console.log(err)
                            openNotification(t("responseCode.networkFailed"))
                        })
                }
                openNotification(res.result ? t("wallet.walletSuccess") : res.message)
            }
        } else if (type === "withdraw") {

            if (props.nftObj.ID && pwd && !pwdError) {
                withdrawNft({ userNftId: props.nftObj.ID, secondPassword: pwd })
                    .then((res) => {
                        if (!res.data.code) {
                            openNotification(t("personalCenter.withdrawNFTSuccess"))
                            gotoStep1()
                        } else {
                            openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
                        }
                    }).catch((error) => {
                        console.log(error)
                        openNotification(t("responseCode.networkFailed"))
                    })
            } else {
                openNotification(t("personalCenter.secondaryPasswordNone"))
            }
        }
    }

    const onModalAction = (action) => {
        setOpen(false)
    }

    useEffect(() => {
        let pwdG = /^\d{6}$/
        if (!pwdG.test(pwd)) {
            setPwdError(true)
        } else {
            setPwdError(false)
        }
    }, [pwd])

    return (
        <>
            <div className='nft-list-containerxx'>
                {
                    nftData.map((item, index) => {
                        return (
                            <div className='nft-item-select'>
                                <div className="nft-item-select-img" key={item.ID}>
                                    <Image preview={false}
                                        className={'nft-img'}
                                        src={item.url + "?imageView2/1/w/300/h/300"}
                                        placeholder={
                                            <Image preview={false}
                                                className={'nft-img'}
                                                src={item.url + "?imageView2/1/w/50/h/50"}
                                            />
                                        }
                                    />
                                    <div className='nft-id'>NftTokenIndex : {item.NftTokenIndex}</div>
                                </div>
                                {props.type === "withdraw" ?
                                    <div className='nft-item-select-detail'>
                                        <div className="input">
                                            <Input.Password
                                                className="form_input"
                                                placeholder={t("personalCenter.pleaseEnterSecondaryPassword")}
                                                value={pwd}
                                                onChange={(e) => { setPwd(e.target.value.trim()) }}
                                                iconRender={(visible) => (visible ? <Image preview={false} src={OpenEye} /> : <Image preview={false} src={OffEye} />)}
                                            />
                                            <div className='input_div'>
                                                <div className={pwdError ? "msg_info_err" : "msg_info"}>
                                                    {t("personalCenter.pleaseEnterSixNumerical")}
                                                </div>
                                                <div className="msg_info" onClick={forgetPwd}>{t("user.forgotPassword")}</div>
                                            </div>
                                        </div>
                                        <div className='wallet_address'>{t("wallet.recipientAddress")}：{props.addr}</div>
                                    </div> : ""
                                }
                            </div>
                        )
                    })
                }
                <div className='option_div'>
                    <div className={load ? "button_common disabled_button" : "button_common primary_button"} onClick={() => { gotoNext(props.type) }}>{t("user.next")}</div>
                </div>
            </div>

            <CommonDialogSmall
                open={open}
                msg={t("wallet.walletNFTAction")}
                cancel={false}
                ok={t("wallet.actionOK")}
                onModalAction={onModalAction}
            />
        </>
    )
}
